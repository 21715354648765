<script>
  import { _ } from "svelte-i18n"
  import { HeaderButton } from "/@/layout/header"
  import { toggleChatbot } from "./chatbot-panel/zva-chatbot"

  export let component = HeaderButton
  let cssClass = ""
  export { cssClass as class }

  const CHAT_BUTTON_ID = "chat-button"

  function clickHandler(event) {
    event.stopPropagation()
    toggleChatbot()
  }
</script>

<svelte:component
  this={component}
  class="help-button {cssClass}"
  icon="Messaging"
  id={CHAT_BUTTON_ID}
  on:click={clickHandler}
>
  {$_("content.trading.chatButton")}
</svelte:component>
