import { get, Writable, writable } from "svelte/store"
import stack from "./contentstack-init"

export const CAM_TEXT_CONTENT_KEY = "cam_text_content"

export enum CamTextContent {
  ACAT_Mutual_Fund,
  ACH_daily_deposit_maximum,
  ACH_deposit_amount_maximum,
  ACH_Deposit_Cutoff,
  ACH_Linking_Bank_Account_Important_Notes,
  ACH_Linking_Bank_Account_Notes_Non_US_bank,
  ACH_Withdrawal_Amount_Maximum,
  ACH_Withdrawal_Cutoff,
  Autotrade_Allocations_Account_Type,
  Autotrade_Allocations_Header,
  Autotrade_Allocations_Header_Sole_Responsibility_Disclaimer,
  Autotrade_Allocations_Manage_Allocations_Modify_This_Allocation_Units,
  Autotrade_Allocations_Manage_Autotrade_Positions_Disclaimer_for_Unit_Style,
  Autotrade_Allocations_Manage_Autotrade_Positions_Switch_to_Self_Managing_this_Position,
  Autotrade_Allocations_Opt_Out,
  Autotrade_Terms_Step_5_Agreements,
  Autotrade_Terms_Step_5_Review_and_Submit,
  Dividend_Reinvesting_Disclaimer,
  Dividend_Reinvesting_Disclaimer_Optout,
  Dividend_Reinvesting_Entity_Trust_Notice,
  Entity_Migration_Login_Banner,
  Footer_Futures_Disclosure,
  Footer_Options_Disclosure,
  Important_Reminder_Updated_Account_Documents,
  Investor_Current_Plan_Update_Disclaimer,
  Manage_W8BEN_Modal,
  My_Accounts_Fully_Paid_Securities_Lending_Header,
  my_accounts_risk_monitoring_expiration_risk_monitoring_disclaimer,
  My_Accounts_Risk_Monitoring_Risk_Monitoring_Description,
  My_Accounts_Risk_Monitoring_Reset_PDT_Important_Modal,
  My_Accounts_Risk_Monitoring_Reset_PDT_Remove_Closing_Only_Modal,
  My_Accounts_Risk_Monitoring_Reset_PDT_Status_Classified_as_PDT,
  My_Accounts_Risk_Monitoring_Reset_PDT_Status_Joint_Account,
  my_accounts_risk_monitoring_reset_PDT_status_not_PDT,
  My_Accounts_Risk_Monitoring_Reset_PDT_Status_Note,
  My_Accounts_Risk_Monitoring_Reset_PDT_Status_Pending,
  My_Money_Available_to_Withdraw_Tooltip,
  My_Money_Crypto_Transfers_Confirm_Your_Identity,
  My_Money_Crypto_Transfers_Deposits_Header,
  My_Money_Crypto_Transfers_All_Page_Cryptocurrency_Disclaimer,
  My_Money_Crypto_Transfers_Enabling_Crypto_Transfers_Footer,
  My_Money_Crypto_Transfers_Enabling_Cryptocurrency_Trading,
  My_Money_Crypto_Transfers_Enabling_Two_Factor_Authentication,
  My_Money_Crypto_Transfers_Information_Warning_Deposits,
  My_Money_Crypto_Transfers_Initial_Crypto_Transfers_Header,
  My_Money_Crypto_Transfers_My_Wallets_Add_New_Wallet_Disclaimer_Pop_up,
  My_Money_Crypto_Transfers_My_Wallets_Header,
  My_Money_Crypto_Transfers_My_Wallet_Supported_Assets_Banner_Deposits,
  My_Money_Crypto_Transfers_My_Wallet_Supported_Assets_Banner_Deposits_and_Withdrawals,
  My_Money_Crypto_Transfers_My_Wallet_Supported_Assets_Banner_Withdrawals,
  My_Money_Crypto_Transfers_Reminder_Warning,
  My_Money_Crypto_Transfers_Reminder_Warning_Withdrawals,
  My_Money_Crypto_Transfers_Withdrawals_Header,
  My_Money_Crypto_Transfers_Withdrawals_Missing_Wallet_Warning,
  My_Money_Deposit_By_ACH_Header,
  My_Money_Deposit_By_Check_Help_Article,
  My_Money_Deposit_By_Check_FAQ_Check_Type,
  My_Money_Deposit_By_Check_FAQ_Deposit_Time,
  My_Money_Deposit_By_Check_FAQ_Rollover_Checks,
  My_Money_Deposit_By_Wire_ABA_Routing_Number_US,
  My_Money_Deposit_By_Wire_ABA_Routing_Number_SWIFT,
  My_Money_Deposit_By_Wire_ABA_Routing_Number_SWIFT_Short,
  My_Money_Deposit_By_Wire_Bank_Name,
  My_Money_Deposit_By_Wire_Bank_Address,
  My_Money_Deposit_By_Wire_FAQ_Required_Information,
  My_Money_Deposit_By_Wire_FAQ_Expect_Wire_Deposit,
  My_Money_Deposit_By_Wire_FAQ_Currency,
  My_Money_Deposit_By_Wire_FAQ_Fees,
  My_Money_Deposit_By_Wire_FAQ_Status,
  My_Money_Deposit_By_Wire_Recipient_Beneficiary_Account_Number,
  My_Money_Deposit_By_Wire_Recipient_Beneficiary_Address,
  My_Money_Deposit_By_Wire_Recipient_Beneficiary_Name,
  My_Money_Deposits_Beneficiary_IRA_Notice,
  My_Money_Enabling_Crypto_Transfers_ID_Confirmation_Failed,
  My_Money_Enabling_Crypto_Transfers_Ineligible_Account_Type_Warning,
  My_Money_Transfers_ACAT_Review_Page_Disclosure,
  My_Money_Transfers_ACAT_Review_Page_Disclosure_Important_Notice,
  My_Money_Transfers_ACAT_Transfer_Ineligible_Account_Type,
  My_Money_Transfers_Internal_Transfers_IRA_Distribution_Conversion_Agreement,
  My_Money_Transfers_Internal_Transfers_Roth_IRA_Distribution_Conversion_Agreement,
  My_Money_Transfers_Internal_Transfers_Step_4_250k_Limit,
  My_Money_Transfers_Internal_Transfers_Step_4_Individual_to_Trad_Roth_SEP_IRA,
  My_Money_Transfers_Internal_Transfers_Step_5_Individual_to_SEP_IRA,
  My_Money_Transfers_Internal_Transfers_Step_5_Roth_to_Traditional_or_SEP_IRA,
  My_Money_Transfers_Internal_Transfers_Step_5_Traditional_to_Roth_IRA,
  My_Money_Withdrawal_Info_Banner_to_Help_Center,
  My_Money_Withdrawals_By_ACH_Header,
  My_Money_Withdrawals_IRA_Date_of_Birth_Note,
  My_Money_Withdrawals_IRA_Distribution_Form_Note,
  My_Money_Withdrawals_Roth_IRA_Distribution_Form_Note,
  My_Money_Withdrawals_Step_5_Required_IRS_Copy_for_IRA_Distributions,
  My_Profile_API_Rejected_Status,
  My_Profile_Beneficiaries_Beneficiary_is_a_Trust_Disclaimer,
  My_Profile_Beneficiaries_No_Beneficiaries_Designated_IRA,
  My_Profile_Beneficiaries_Not_Applicable_Account_Type,
  My_Profile_Beneficiaries_Skip_for_Now_Disclaimer,
  My_Profile_Communication_Preferences,
  My_Profile_Investor_Important_Entity_Banner,
  My_Profile_Open_API,
  My_Profile_Personal_Information_Note_48hrs_for_Address_Updates,
  My_Profile_W8BEN_Recertification_Notice,
  One_Linked_Bank_Account_Note,
  Summary_Document_Upload_Note,
  Tax_Center_Consolidated_1099_Guide_Definition,
  Tax_Center_IRA_Tax_Form_Guide_Definition,
  Tax_Center_International_Accounts_Taxes_Guide,
  Tastytrade_Mailing_Address,
  Tastytrade_Mailing_Address_Only,
  Tastytrade_Mailing_Name_Only,
  Trading_Preferences_Enable_Cryptocurrency_Trading_Zero_Hash_Acknowledgement,
  Trading_Preferences_Enable_Short_Calls_IRA_Update_Investor_Profile,
  Trading_Preferences_Intraday_Futures_Margin,
  Trading_Preferences_Enable_Portfolio_Margin,
  W8BEN_Disclaimer,
  W8BEN_Subheader,
  W8BEN_Recertification_Banner,
}

export interface LoginTextContent {
  warning: string
  contact_support: string
}

export const contentstackContent: Writable<Map<string, string>> = writable(
  new Map<string, string>()
)

async function fetchContentStackJson(type: string) {
  return stack.ContentType(type).Query().toJSON().find()
}

async function fetchTotalContentStackEntries(type: string) {
  return stack.ContentType(type).Query().count().toJSON().find()
}

async function fetchContentStackPage(type: string, limit: number, skip = 0) {
  return (
    await stack
      .ContentType(type)
      .Query()
      .skip(skip)
      .limit(limit)
      .toJSON()
      .find()
  )[0]
}

export async function fetchAll(
  type: string,
  toMapEntry: (entry: any) => any[],
  limit = 100
): Promise<Map<any, any>> {
  const [entryCount] = await fetchTotalContentStackEntries(type)
  const totalPages = Math.ceil(entryCount / limit)

  const promises = []

  for (let page = 0; page < totalPages; page++) {
    promises.push(fetchContentStackPage(type, limit, page * limit))
  }

  const results = await Promise.all(promises)
  const contentMap = new Map<string, string>()
  results.forEach((page) =>
    page.forEach((entry) => {
      const [key, value] = toMapEntry(entry)
      contentMap.set(key, value)
    })
  )

  return contentMap
}

export async function fetchAllContent(type: string, limit = 100) {
  const toMapEntry = (entry: { identifier: string; text: string }) => [
    entry.identifier,
    entry.text,
  ]
  const contentMap = await fetchAll(type, toMapEntry, limit)
  contentstackContent.set(contentMap)
}

export async function fetchLoginContent(): Promise<LoginTextContent> {
  const json = await fetchContentStackJson("wb2_login")
  const content = json[0][0]
  return content
}

export interface ContentStackArtCard {
  title: string
  imageUrl: string
  linkTitle: string
  linkUrl: string
}

export async function fetchLoginArtCards(): Promise<ContentStackArtCard[]> {
  const content = await fetchContentStackJson("app_login_art_card")

  return content[0].map((json: any): ContentStackArtCard => {
    return {
      title: json.title,
      imageUrl: json.image.url,
      linkTitle: json.link.title,
      linkUrl: json.link.href,
    }
  })
}

export async function fetchMobileArtCards(): Promise<ContentStackArtCard[]> {
  const content = await fetchContentStackJson("app_login_art_card")

  return content[0].map((json: any): ContentStackArtCard => {
    return {
      title: json.title,
      imageUrl: json.compact_image.url,
      linkTitle: json.link.title,
      linkUrl: json.link.href,
    }
  })
}

export function contentFor(id: number) {
  return get(contentstackContent).get(CamTextContent[id]) ?? ""
}
