import { get } from "svelte/store"
import {
  zvaChatbotPanelVisible,
  helpCenterPanelVisible,
  leftSidebarCollapsed,
  media,
  rightSidebarCollapsed,
  rightSidebarVisible,
} from "/@/layout"
import { pushSnowplowEvent } from "/@/util/snowplow"

export function toggleHelpCenter() {
  if (get(zvaChatbotPanelVisible)) {
    zvaChatbotPanelVisible.set(false)
  }
  helpCenterPanelVisible.update((value) => !value)

  if (get(media).mobile) {
    leftSidebarCollapsed.set(true)
  }
  const isVisible = get(helpCenterPanelVisible)
  if (isVisible) {
    rightSidebarCollapsed.set(false)
    rightSidebarVisible.set(true)
  }
  pushSnowplowEvent("help_button_click")
}
