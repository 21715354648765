<script context="module">
  export const HEADER_HEIGHT = 48 // this is --vertical-meter * 1.5, getCssPropertyValueNumeric isn't working right in context=module
</script>

<script lang="ts">
  let cssClass = ""
  export { cssClass as class }
</script>

<header
  class="
    header-bar
    flex
    max-w-[100vw]
    items-center
    justify-start
    bg-general-primary-surface
    md:pr-small
    mobile:pl-0
    {cssClass}"
  style:grid-area="header"
  style:z-index="var(--header-z-index)"
  style:height="calc(var(--vertical-meter) * 1.5)"
>
  <slot />
</header>
