<script lang="ts">
  import { Alert, AlertSeverity } from "/@/control/alert"
  import { toast } from "@zerodevx/svelte-toast"

  const severity = AlertSeverity.INFO

  export let toastId = null
  export let updateServiceWorker

  function reload() {
    console.log("SW reloading")
    updateServiceWorker(true)
    toast.pop(toastId)
  }
</script>

<Alert {severity} class="max-w-96">
  <p>
    The web platform has been updated. Reload now to get the latest features.
  </p>
  <div class="mt-2.5">
    <button
      class="button h-10 w-full bg-button-continue hover:bg-button-continue-hover"
      on:click={reload}>Reload</button
    >
  </div>
</Alert>
