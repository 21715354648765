import { pushSnowplowEvent } from "./snowplow"
import {
  IG_APP,
  LOGIN_PAGE,
  clearSession,
  getStoredSessionToken,
  redirectToMyIg,
  redirectWithPath,
} from "/@lib/shared"
import { twApiClient } from "/@lib/tastyworks-rest"

export function logout() {
  const token = getStoredSessionToken()
  return twApiClient.sessionService.logout(token).then(() => {
    clearSession()
    pushSnowplowEvent("customer_logout")

    if (IG_APP) {
      redirectToMyIg()
    } else {
      redirectWithPath(LOGIN_PAGE)
    }
  })
}
