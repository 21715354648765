<script lang="ts" context="module">
  interface RouteDetail extends SvelteRouterRouteDetail {
    userData: object & {
      hasOpenAccounts: boolean
      conditionFailedUrl?: string
    }
  }

  interface ConditionsFailedEvent extends SvelteRouterConditionsFailedEvent {
    detail: RouteDetail
  }
</script>

<script lang="ts">
  import { captureException } from "@sentry/browser"
  import { SvelteComponent } from "svelte"
  import Router, {
    push,
    replace,
    RouteLoadedEvent,
    ConditionsFailedEvent as SvelteRouterConditionsFailedEvent,
    RouteDetail as SvelteRouterRouteDetail,
    WrappedComponent,
  } from "svelte-spa-router"
  import wrap from "svelte-spa-router/wrap"
  import InitializationError from "./InitializationError.svelte"
  import {
    APP_ROOT_REDIRECT,
    DASHBOARD,
    ERROR,
    LANDING_PAGE_REDIRECT,
    MANAGE,
    TRADING,
  } from "./routes"
  import { hasOpenAccounts } from "/@/account-management/store/account"
  import { LoadingOverlay, PendingAccountsView } from "/@/control"
  import { getMobileOS, loadTradingSession } from "/@/logged-in/util"
  import TradingLoadingOverlay from "/@/trading/TradingLoadingOverlay.svelte"
  import { w8BenStore } from "/@/w-8ben/store"
  import { CAM_TEXT_CONTENT_KEY, fetchAllContent } from "/@lib/contentstack"
  import {
    loadBacktestingContent,
    loadSmallsExchangeContent,
  } from "/@lib/contentstack-feature"
  import { loadIgContent } from "/@lib/contentstack-ig"
  import { loadZeroHashContent } from "/@lib/contentstack-zh"
  import { allowUniqueRedirectBypass, IG_APP, isAdmin } from "/@lib/shared"
  import { DASHBOARD_FUNDING_JOURNEY_ENABLED } from "/@/dashboard/feature"

  const agent = getMobileOS()

  function handleConditionsFailed(e: ConditionsFailedEvent) {
    const { hasOpenAccounts, conditionFailedUrl } = e.detail.userData

    if (!hasOpenAccounts && conditionFailedUrl) {
      push(conditionFailedUrl)
    }
  }

  async function loadTradingLayout() {
    try {
      loadSmallsExchangeContent()
      loadBacktestingContent()
      loadZeroHashContent()
      await loadTradingSession()
      await w8BenStore.initialize()
      return IG_APP
        ? await import("/@/trading/ig/IgTradingLayout.svelte")
        : await import("/@/trading/TradingLayout.svelte")
    } catch (error) {
      console.error(error)
      captureException(error)
      replace("/error")
    }
  }

  function determineLandingPage(): Promise<string> {
    if ($hasOpenAccounts) {
      return loadTradingSession().then(({ appTwSession }) => {
        const { path } =
          appTwSession.remoteWebPreferences.landingPage.getValue()
        return path
      })
    }
    return Promise.resolve(MANAGE.path)
  }

  function determinePath(): string {
    if (isAdmin()) {
      return TRADING.path
    }

    if (!$hasOpenAccounts) {
      return MANAGE.path
    }

    return LANDING_PAGE_REDIRECT.path
  }

  function redirectHandler(event: RouteLoadedEvent) {
    switch (event.detail.route) {
      case APP_ROOT_REDIRECT.path:
        replace(determinePath())
        break
      case LANDING_PAGE_REDIRECT.path:
        determineLandingPage().then(replace)
        break
    }
  }

  async function loadManageAccountLayout() {
    loadIgContent()
    fetchAllContent(CAM_TEXT_CONTENT_KEY)
    if (!allowUniqueRedirectBypass()) {
      await w8BenStore.initialize()
    }

    return IG_APP
      ? import("/@/account-management/ig/IgManageAccountsLayout.svelte")
      : import("/@/account-management/ManageAccountsLayout.svelte")
  }

  const routes = new Map<
    string,
    typeof SvelteComponent<any> | WrappedComponent
  >([
    [APP_ROOT_REDIRECT.path, LoadingOverlay],
    [LANDING_PAGE_REDIRECT.path, TradingLoadingOverlay],
    ["/dashboard/pending-accounts", PendingAccountsView],
    ["/trading/pending-accounts", PendingAccountsView],
    [
      DASHBOARD.glob,
      wrap({
        asyncComponent: async () => {
          await loadTradingSession()

          if ($DASHBOARD_FUNDING_JOURNEY_ENABLED) {
            return IG_APP
              ? import(
                  "/@/dashboard/ig/IgAccountFundingJourneyDashboard.svelte"
                )
              : import("/@/dashboard/AccountFundingJourneyDashboard.svelte")
          }

          return IG_APP
            ? import("/@/dashboard/ig/IgDashboard.svelte")
            : import("/@/dashboard/Dashboard.svelte")
        },
        conditions: [() => $hasOpenAccounts],
        loadingComponent: TradingLoadingOverlay,
        loadingParams: {
          text: "Loading Dashboard",
        },
        userData: {
          hasOpenAccounts: $hasOpenAccounts,
          conditionFailedUrl: "/dashboard/pending-accounts",
        },
      }),
    ],
    [
      TRADING.glob,
      wrap({
        asyncComponent: loadTradingLayout,
        conditions: [() => $hasOpenAccounts],
        loadingComponent: TradingLoadingOverlay,
        loadingParams: {
          text: "Loading Trading",
        },
        props: { agent },
        userData: {
          hasOpenAccounts: $hasOpenAccounts,
          conditionFailedUrl: "/trading/pending-accounts",
        },
      }),
    ],
    [
      MANAGE.glob,
      wrap({
        asyncComponent: loadManageAccountLayout,
        loadingComponent: LoadingOverlay,
        loadingParams: {
          text: "Loading Account Management",
        },
      }),
    ],
    [ERROR.path, InitializationError],
  ])
</script>

<Router
  on:conditionsFailed={handleConditionsFailed}
  on:routeLoaded={redirectHandler}
  {routes}
/>
