import { writable } from "svelte/store"

import { fetchAll } from "./contentstack"

const BACKTESTING_CONTENT_TYPE_KEY = "backtesting"
const SMALLS_CONTENT_TYPE_KEY = "small_exchange_v2"

export enum BacktestingContent {
  backtesting_ig_disclaimer,
  backtesting_tasty_disclaimer,
}

export enum SmallsContent {
  small_exchange_platform_disclosure,
  small_exchange_create_allocation_empty_state,
}

export const backtestingContentStore = writable(new Map<string, string>())
export const smallsContentStore = writable(new Map<SmallsContent, string>())

export async function loadBacktestingContent() {
  const content = await fetchAll(
    BACKTESTING_CONTENT_TYPE_KEY,
    (entry: { identifier: string; text: string }) => [
      BacktestingContent[entry.identifier],
      entry.text,
    ]
  )
  backtestingContentStore.set(content)
}

export async function loadSmallsExchangeContent() {
  const content = await fetchAll(
    SMALLS_CONTENT_TYPE_KEY,
    (entry: { identifier: string; text: string }) => [
      SmallsContent[entry.identifier],
      entry.text,
    ]
  )
  smallsContentStore.set(content)
}
