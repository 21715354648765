<script>
  import HamburgerButton from "./HamburgerButton.svelte"
  import MobileHeaderNav from "./MobileHeaderNav.svelte"
  import { isDashboard } from "/@/layout"
  import Logo from "/@/layout/Logo.svelte"

  export let navItems
  export let showMenuButton = true
</script>

<HamburgerButton
  class="hamburger-btn-mobile max-w-[calc(0.75_*_var(--horizontal-meter))]"
  {showMenuButton}
/>
<slot>
  <Logo height="50%" class={$isDashboard && "ml-small"} />
</slot>
<div class="flex-1" />
<MobileHeaderNav {navItems} />
