
      import { get } from "svelte/store"
      import { zvaChatbotPanelVisible } from "/@/layout"

      function closeChatPanel() {
        if (get(zvaChatbotPanelVisible)) {
          zvaChatbotPanelVisible.set(false)
        }
      }
      window.addEventListener("beforeunload", closeChatPanel)
    