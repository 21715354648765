import { isProduction } from "../environment"
import { flagStore } from "/@lib/launch-darkly"
import { readable } from "svelte/store"

export const DASHBOARD_FUNDING_JOURNEY_ENABLED = isProduction()
  ? flagStore("dashboard-funding-journey", {
      defaultValue: false,
      realtime: true,
    })
  : readable(true)
