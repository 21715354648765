import { get, Readable, writable } from "svelte/store"
import { accountOpeningState } from "/@/account-management/store/account"
import { AlertSeverity, toastAlert } from "/@/control/alert"
import { customer } from "/@/util/customer"
import { twApiClient } from "/@lib/tastyworks-rest"

export const GO_TO_VERIFY = "go-to-verify"

interface W8BenStore extends Readable<boolean> {
  initialize: () => Promise<boolean>
  updateRequired: (required: boolean) => void
}

const { subscribe, set } = writable(false)

export const w8BenStore: W8BenStore = {
  async initialize() {
    if (
      !get(customer)?.address.isForeigner ||
      !get(accountOpeningState).hasAccounts
    ) {
      set(false)
      return false
    }

    const response = await twApiClient.w8BenService.show()

    if (response.isError) {
      toastAlert(AlertSeverity.ERROR, "Unable to fetch W-8 BEN status")
      set(false)
      return false
    }

    const { recertificationRequired } = response.data
    set(recertificationRequired)
    return recertificationRequired
  },
  subscribe,
  updateRequired(required: boolean) {
    set(required)
  },
}
