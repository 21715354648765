import initApp from "/@/init-app"
import App from "./App.svelte"
import { checkForSwUpdate } from "/@/util/sw"

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(async (registration) => {
    await checkForSwUpdate(registration)
  })
}

export default initApp(App)
