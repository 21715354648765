<script lang="ts">
  import ModalDialog from "/@/layout/modal/ModalDialog.svelte"
  import { createEventDispatcher, tick } from "svelte"

  export let title: string
  export let message: string

  // Common dialogue props.
  export let show = false
  export let lightbox = true
  export let height = "calc(8 * var(--vertical-meter))"
  export let width = "calc(8 * var(--horizontal-meter))"

  const dispatch = createEventDispatcher()

  function close() {
    dispatch("close")
  }

  let closeButton: HTMLButtonElement

  async function stealFocus(open: boolean) {
    if (open) {
      await tick()
      closeButton.focus()
    }
  }

  // XXX: Focus close-button when open to use default keyboard events.
  $: stealFocus(show)
</script>

<ModalDialog class="modal-alert" {show} {lightbox} {height} {width}>
  <div
    slot="title"
    class="
      flex
      w-full
      flex-row
      items-center
      justify-between
      text-general-secondary-text
      font-x-large-600"
  >
    <h3>{title}</h3>
    <button class="tw-icon-Close" on:click={close} />
  </div>

  <svelte:fragment slot="body">{message}</svelte:fragment>

  <svelte:fragment slot="actions">
    <button
      class="button-base form-action button w-1/3"
      bind:this={closeButton}
      on:click={close}
    >
      <div class="flex flex-row gap-small">
        <span>Close</span>
        <span class="tw-icon-Close" />
      </div>
    </button>
  </svelte:fragment>
</ModalDialog>
