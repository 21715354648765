/**
 * routes and related utils
 *
 * note: this module uses dynamic imports to prevents premature loading
 * of uninitialized modules (i.e. boulangerie). since those parts of the code
 * should not yet be loaded at this point, they should only be imported as required
 * and the user should be notified via spinner-text when boulangerie is being loaded
 */
import { replace } from "svelte-spa-router"
import {
  headerNavDashboardLink,
  headerNavManageAccountsLink,
  headerNavTradingPlatformLink,
} from "../util/user-analytics"
import LandingPagePrompt from "./LandingPagePrompt.svelte"
import { asyncDialog } from "/@/layout/modal"
import { loadTradingSession } from "/@/logged-in/util"
import { lock } from "/@/util"
import { isAdmin } from "/@lib/shared"
import { tourToastAlert } from "../control/alert/toast-alert"
import { AlertSeverity } from "../control/alert"
import { toggleHelpCenter } from "./help-center-panel/help-center"

class Route {
  constructor(public path: string) {}
  get glob() {
    return `${this.path}*`
  }
}

export const TRADING = new Route("/trading/")
export const MANAGE = new Route("/manage/")
export const DASHBOARD = new Route("/dashboard/")
export const ERROR = new Route("/error/")
export const APP_ROOT_REDIRECT = new Route("/")
export const LANDING_PAGE_REDIRECT = new Route("/landing/")
export const CATCH_ALL = new Route("")

export function setDashboardAsLandingPageAndRedirect(landingPage) {
  const jsWebPage = landingPage.selectableValues
    .toArray()
    .find((opt) => opt.path === DASHBOARD.path)
  landingPage.setValue(jsWebPage)
  replace(jsWebPage.path)
}

export const promptForLandingPage = isAdmin()
  ? async () => {}
  : lock(async () => {
      const { appTwSession } = await loadTradingSession()
      const { hasBeenPromptedForDashboardAsLandingPage, landingPage } =
        appTwSession.remoteWebPreferences

      if (!hasBeenPromptedForDashboardAsLandingPage.getValue()) {
        const accepted = await asyncDialog<boolean>(LandingPagePrompt)

        hasBeenPromptedForDashboardAsLandingPage.setValue(true)

        if (accepted) {
          setDashboardAsLandingPageAndRedirect(landingPage)
        }
      }
    })

export const showTourToastForUsers = async () => {
  const tradingSession = await loadTradingSession()
  if (!tradingSession) {
    return
  }

  const { appTwSession } = tradingSession
  const { hasSeenTourToastNotification } = appTwSession.remoteWebPreferences
  if (!hasSeenTourToastNotification.getValue()) {
    hasSeenTourToastNotification.setValue(true)
    tourToastAlert(
      "Explore Tours",
      "We've created tours and videos to help you get familiar with our platform and the basics of trading. You can access them anytime by clicking on 'Help'.",
      () => {
        toggleHelpCenter()
      },
      () => {},
      {},
      AlertSeverity.INFO,
      { type: "exploreTours" }
    )
  }
}

export interface NavItem {
  id: string
  label: string
  href: string
  glob: string
  analyticsId: string
}

export const DASHBOARD_NAV_ITEM: NavItem = {
  analyticsId: headerNavDashboardLink,
  glob: DASHBOARD.glob,
  href: DASHBOARD.path,
  label: "Dashboard",
  id: "dashboard-link",
}
export const TRADING_NAV_ITEM = {
  analyticsId: headerNavTradingPlatformLink,
  glob: TRADING.glob,
  href: TRADING.path,
  label: "Trading",
  id: "trading-link",
}
export const MANAGE_NAV_ITEM = {
  analyticsId: headerNavManageAccountsLink,
  glob: MANAGE.glob,
  href: MANAGE.path,
  label: "Manage",
  id: "manage-link",
}
