<script>
  import { _ } from "svelte-i18n"
  import { HeaderButton } from "/@/layout/header"
  import { toggleHelpCenter } from "./help-center-panel/help-center"

  export let component = HeaderButton
  let cssClass = ""
  export { cssClass as class }

  const HELP_BUTTON_ID = "ZVA-help-button"

  function clickHandler(event) {
    event.stopPropagation()
    toggleHelpCenter()
  }
</script>

<svelte:component
  this={component}
  class="help-button {cssClass}"
  icon="Help"
  id={HELP_BUTTON_ID}
  on:click={clickHandler}
>
  {$_("content.trading.helpButton")}
</svelte:component>
