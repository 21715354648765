import { get } from "svelte/store"
import {
  helpCenterPanelVisible,
  leftSidebarCollapsed,
  media,
  rightSidebarCollapsed,
  rightSidebarVisible,
  salesforceInitializedSuccessfully,
  zvaChatbotPanelVisible,
} from "/@/layout"

export function toggleChatbot() {
  const wasHelpCenterVisible = get(helpCenterPanelVisible)

  if (get(helpCenterPanelVisible)) {
    helpCenterPanelVisible.set(false)
  }

  if (wasHelpCenterVisible) {
    setTimeout(() => {
      zvaChatbotPanelVisible.update((value) => !value)
    }, 100)
  } else {
    zvaChatbotPanelVisible.update((value) => !value)
  }

  if (get(media).mobile) {
    leftSidebarCollapsed.set(true)
  }

  const isVisible = get(zvaChatbotPanelVisible)
  if (isVisible) {
    rightSidebarCollapsed.set(false)
    rightSidebarVisible.set(true)
  }
}

export async function attemptToLoadSalesforceScript() {
  return new Promise((resolve) => {
    const scriptElement = document.createElement("script")
    scriptElement.src =
      "https://service.force.com/embeddedservice/5.0/esw.min.js"

    scriptElement.onload = () => {
      salesforceInitializedSuccessfully.set(true)
      resolve(true)
      document.body.removeChild(scriptElement)
    }

    scriptElement.onerror = () => {
      salesforceInitializedSuccessfully.set(false)
      resolve(false)
      document.body.removeChild(scriptElement)
    }
    document.body.appendChild(scriptElement)
  })
}

/**
 * initialize embedded salesforce service. This allows us to use the ZVA chatbot.
 * This will expose
 */
export async function initSalesforce() {
  await attemptToLoadSalesforceScript()
  if (window.embedded_svc) {
    const embedded_svc = window.embedded_svc
    embedded_svc.settings.displayHelpButton = false
    embedded_svc.settings.language = "" //For example, enter 'en' or 'en-US'

    // Setting the prepopulatedPrechatFields / extraPrechatFormDetails
    // ZVA sets the form fields in its own handoff script, but Solvvy does not
    if (embedded_svc.settings.extraPrechatFormDetails === undefined) {
      embedded_svc.settings.prepopulatedPrechatFields = {
        RecordTypeId: "0128d000000d3ml",
        Origin: "Chat",
        Status: "New",
      }

      embedded_svc.settings.extraPrechatFormDetails = [
        {
          value: "0128d000000d3ml",
          displayToAgent: false,
        },
        {
          label: "Ticket Origin",
          value: "Chat",
          displayToAgent: true,
        },
        {
          label: "Status",
          value: "New",
          displayToAgent: true,
        },
        {
          label: "First Name",
          transcriptFields: ["TT_First_Name__c"],
          displayToAgent: true,
        },
        {
          label: "Last Name",
          transcriptFields: ["TT_Last_Name__c"],
          displayToAgent: true,
        },
        {
          label: "Email",
          transcriptFields: ["TT_Email__c"],
          displayToAgent: true,
        },
      ]
    }

    embedded_svc.settings.enabledFeatures = ["LiveAgent"]
    embedded_svc.settings.entryFeature = "LiveAgent"
    embedded_svc.init(
      "https://tastyworks.my.salesforce.com",
      "https://support.tastytrade.com/",
      "https://service.force.com",
      "00D8d000007WeWg",
      "Solvvy_Chat",
      {
        baseLiveAgentContentURL:
          "https://c.la1-core1.sfdc-5pakla.salesforceliveagent.com/content",
        baseLiveAgentURL:
          "https://d.la1-core1.sfdc-5pakla.salesforceliveagent.com/chat",
        buttonId: "5738d000000onbd",
        deploymentId: "5728d000000omsW",
        eswLiveAgentDevName: "Solvvy_Chat",
        isOfflineSupportEnabled: false,
      }
    )
  }
}
