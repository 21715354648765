<script>
  import { IconButton } from "/@/control"

  export let icon
  export let userAnalyticsTag = null
  let cssClass = ""
  export { cssClass as class }
  export let id = undefined
  export let href = undefined
  export let target = undefined
</script>

<IconButton
  labelClass="font-x-small-400 text-general-primary-label"
  class="
    header-button
    leading-triple-extra-large
    mx-double-extra-small
    text-general-primary-label
    icon-medium
    {cssClass}"
  {userAnalyticsTag}
  {icon}
  {id}
  {href}
  {target}
  after
  on:click
>
  <slot />
</IconButton>
